@tailwind base;
@tailwind components;
@tailwind utilities;

.container {
  width: 100%;
}

@media (min-width: 1280px){
  .container {
    max-width: 1280px;
    /* min-width: 1140px; */
  }
}

@media (min-width: 576){
  .container {
    max-width: 97%;
  }
}  

body {
  background-color: #1D1621;
  margin: 0;
  font-family: 'Aeonik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,h2,h3 {
  font-family: 'Aeonik-Medium';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: Aeonik;
  src: url('../public/assets/fonts/Aeonik/Aeonik-Regular.otf') format('opentype');
  font-weight: normal;
}

@font-face {
  font-family: Aeonik-Bold;
  src: url('../public/assets/fonts/Aeonik/Aeonik-Bold.otf') format('opentype');
  font-weight: bolder;
}

@font-face {
  font-family: Aeonik-Medium;
  src: url('../public/assets/fonts/Aeonik/Aeonik-Medium.otf') format('opentype');
  font-weight: bold;
}

.floaty {
    background: rgba(233, 207, 208, 0.01);
    box-shadow: inset 0px 5.07px 13.0273px -8.37468px rgba(255, 255, 255, 0.5), inset 0px 1.62841px 1px -0.93052px #FFFFFF, inset 0px -19.0757px 15.82px -14.8883px rgba(121, 108, 145, 0.15), inset 0px 22.7977px 23.263px -11.1662px rgba(202, 172, 255, 0.2), inset 0px 0.93052px 4.18734px rgba(154, 146, 210, 0.3), inset 0px 0.23263px 9.3052px rgba(227, 222, 255, 0.2);
    backdrop-filter: blur(32.7411px);
}

.floaty:hover{
    animation: pulse-floaty 2s infinite;
}

@keyframes pulse-floaty {
    0% {
      /* transform: scale(0.95); */
      box-shadow: inset 0px 5.07px 13.0273px -8.37468px rgba(255, 255, 255, 0.5), inset 0px 1.62841px 1px -0.93052px #FFFFFF, inset 0px -19.0757px 15.82px -14.8883px rgba(121, 108, 145, 0.15), inset 0px 22.7977px 23.263px -11.1662px rgba(202, 172, 255, 0.2), inset 0px 0.93052px 4.18734px rgba(154, 146, 210, 0.3), inset 0px 0.23263px 9.3052px rgba(227, 222, 255, 0.2);
    }
    
    70% {
      /* transform: scale(1); */
      box-shadow: inset 0px 5.07px 13.0273px -8.37468px rgba(255, 255, 255, 0), inset 0px 1.62841px 1px -0.93052px #FFFFFF, inset 0px -19.0757px 15.82px -14.8883px rgba(121, 108, 145, 0), inset 0px 22.7977px 23.263px -11.1662px rgba(202, 172, 255, 0), inset 0px 0.93052px 4.18734px rgba(154, 146, 210, 0), inset 0px 0.23263px 9.3052px rgba(227, 222, 255, 0);
    }
    
    100% {
      /* transform: scale(0.95); */
      box-shadow: inset 0px 5.07px 13.0273px -8.37468px rgba(255, 255, 255, 0), inset 0px 1.62841px 1px -0.93052px #FFFFFF, inset 0px -19.0757px 15.82px -14.8883px rgba(121, 108, 145, 0), inset 0px 22.7977px 23.263px -11.1662px rgba(202, 172, 255, 0), inset 0px 0.93052px 4.18734px rgba(154, 146, 210, 0), inset 0px 0.23263px 9.3052px rgba(227, 222, 255, 0);
    }
  }
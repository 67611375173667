@keyframes move-twinkle-back {
    from {background-position: 0 0;}
    to {background-position: -10000px 5000px;}
}

.stars, .twinkling {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: block;
}

.twinkling {
    z-index: 1;
    animation: move-twinkle-back 500s linear infinite;
}

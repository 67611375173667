$black: #141414;
$yellowGreen: yellowgreen;
$yellow: yellow;
$royalblue: royalblue;
$magenta: magenta;

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overscroll-behavior: none;
  -ms-overflow-style: none;
  font-size: 16px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.scroll-container {
  position: sticky;
  overflow-x: hidden;
  top:0;
  left: 0;
  right: 0;
  will-change: transform;

  .ghost {
    width: 100vw;
  }
}

.thumbnails-container {
  position: relative;
  height: 100vh;
  width: max-content;
  display: flex;
  align-items: center;

  .thumbnails {
    position: relative;
    display: flex;

    & > *:not(:last-child) {
      margin-right: 45px;
    }

    .thumbnail {
      height: 40vh;
      width: 700px;
      background-color: $royalblue;
      border: 15px solid magenta;
    }
  }
}
@keyframes glowing {
  0%, 20%, 100% { text-shadow: none; }
  10%, 30% { text-shadow: 0 0 20px #AAAAAA, 0 0 30px rgba(255, 255, 255, 0.1), 0 0 40px rgba(255, 255, 255, 0.1), 0 0 50px rgba(255, 255, 255, 0.1); }
}

.glowing-text {
  animation: glowing 12s linear infinite;
}

